<template>
  <div>
    <Title :pages="[{ icon: 'users', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <Modal
        v-if="create_access"
        :button_name="$t('Crear') + ' ' + modal_title"
        :modal_title="modal_title"
        :formBtn="formBtn"
        :dialog="dialog"
        :parent="parent"
        :service="service"
        @updateDialog="updateDialog"
        @loadList="loadList(service)"
        :item="mainItem"
        @updateItem="
          (newItem) => {
            mainItem = newItem;
          }
        "
      />

      <!--Filter-->
      <div class="row mb-3" v-if="list_access">
        <div class="col-md-12">
          <div class="filter">
            <div class="d-flex align-items-center">
              <h4 class="title">{{ $t("Filtros") }}</h4>
              <font-awesome-icon icon="filter" />
            </div>
            <hr />
            <div class="row">
              <div class="col-md-4">
                <v-text-field
                  data-vv-as="nombre"
                  :label="$t('Nombre')"
                  name="zone"
                  v-model="name_filter"
                ></v-text-field>
              </div>
              <div class="col-md-4">
                <v-checkbox
                  :label="$t('Activo')"
                  v-model="active_filter"
                ></v-checkbox>
              </div>

              <div
                class="col-md-4 d-flex align-items-center justify-content-end pr-10"
              >
                <v-btn
                  color="primary"
                  dark
                  v-on="on"
                  @click="loadList(service)"
                  >{{ $t("Buscar") }}</v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Filter -->

      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ page_title }}</span>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            :label="$t('Buscar')"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list"
          :search="search"
          :loading="loading"
          :loading-text="$t('cargando_espere')"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:item="props">
            <tr>
              <td>
                {{
                  (pagination.page - 1) * pagination.itemsPerPage +
                    props.index +
                    1
                }}
              </td>
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.username }}</td>
              <td>{{ props.item.rol.name }}</td>
              <td>
                <v-icon v-if="props.item.isExternal"> check </v-icon>
              </td>
              <td>
                {{
                  props.item.isExternal
                    ? props.item.companyExternal.nameClient
                    : ""
                }}
              </td>
              <td class="justify-content-center layout px-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-if="update_access"
                      v-on="on"
                      small
                      color="blue"
                      class="mr-2"
                      @click="editItem(props.item)"
                    >
                      edit
                    </v-icon>
                  </template>
                  <span>{{ $t("Editar") }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      small
                      color="blue"
                      class="mr-1"
                      @click="viewItem(props.item)"
                    >
                      remove_red_eye
                    </v-icon>
                  </template>
                  <span>{{ $t("Ver") }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-if="delete_access"
                      v-on="on"
                      small
                      color="red"
                      class="mr-2"
                      @click="deleteItem(props.item)"
                    >
                      delete
                    </v-icon>
                  </template>
                  <span>{{ $t("Eliminar") }}</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ $t("sin_resultados") + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <div v-if="cant_pages > 1" class="text-center">
        <v-pagination
          class="mt-5 mb-5"
          v-model="current_page"
          :length="cant_pages"
          total-visible="5"
          circle
          @next="loadPage(service)"
          @previous="loadPage(service)"
          @input="loadPage(service)"
        ></v-pagination>
      </div>

      <DialogDelete
        :dialog_delete="dialog_delete"
        @updateDialogDelete="updateDialogDelete"
        @deleteItemConfirm="deleteItemConfirm"
      />
    </v-container>
  </div>
</template>

<script>
import { usersService } from "@/libs/ws/usersService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import Modal from "./Modal.vue";
import RolMixin from "@/mixins/RolMixin.vue";

export default {
  components: { Title, Modal, DialogDelete },
  mixins: [GenericMixin, RolMixin],
  RolMixin,
  data() {
    return {
      parent: this,
      service: usersService,
      page_title: this.$t("Usuario") + "s",
      modal_title: this.$t("Usuario"),
      mainItem: {
        id: "",
        name: "",
        username: "",
        password: "",
        email: "",
        mobileNumber: "",
        idRol: "",
        isActive: true,
        organizationBaseId: "",
        isExternal: false,
        idCompanyExternal: "",
      },
      defaultItem: null,
      headers: [
        { text: this.$t("Numero"), value: "number" },
        {
          text: this.$t("Nombre"),
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: "Username",
          align: "left",
          sortable: true,
          value: "username",
        },

        {
          text: "Rol",
          align: "left",
          sortable: true,
          value: "rol.name",
        },

        {
          text: "Externo",
          align: "left",
          sortable: true,
          value: "isExternal",
        },
        {
          text: "Cliente",
          align: "left",
          sortable: true,
          value: "companyExternal.nameClient",
        },
        { text: this.$t("Accion"), value: "enable", align: "center" },
      ],
      list: [],
      dialog_delete: false,
    };
  },
  mounted() {
    this.defaultItem = Object.assign({}, this.mainItem);
    this.loadList(this.service);
  },
  methods: {
    deleteItemConfirm() {
      this.delete(this.service);
      this.dialog_delete = false;
      this.loadList(this.service);
    },
  },
};

// TODO agregar un boton de limpiar busqueda.
</script>
