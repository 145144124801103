<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" v-on:input="$emit('updateDialog', false)" max-width="900px" height="80%">
      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <v-stepper v-model="e1">
              <v-stepper-header class="elevation-0">
                <v-stepper-step :complete="e1 > 1" step="1">
                  Seleccionar ruta
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="e1 > 2" step="2">
                  Confirmar remitos
                </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-card class="" color="#e1e1e1" height="initial">
                    <div class="col-md-12">
                      <div class="row ">
                        <div class="col-md-5">
                          <v-menu ref="menu" :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field v-model="dateInicio_filter" :label="$t('Fecha_desde')" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="dateInicio_filter" min="1950-01-01"></v-date-picker>
                          </v-menu>
                        </div>
                        <div class="col-md-5">
                          <v-menu ref="menu" :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field v-model="dateFin_filter" :label="$t('Fecha_hasta')" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="dateFin_filter" min="1950-01-01"></v-date-picker>
                          </v-menu>
                        </div>
                        <div class="col-md-2">
                          <v-btn color="primary" class="mt-3 float-right" dark @click="filterRutas()">{{ $t("Buscar") }}</v-btn>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <v-data-table :headers="headersRutas" :items="rutasStepper" v-model="selected_stepper" show-select :single-select="true" hide-default-footer disable-pagination>
                        <template v-slot:item="props">
                          <tr>
                            <td>
                              <v-checkbox :input-value="props.isSelected" style="margin:0px;padding:0px ;" hide-details @change="props.select($event)" />
                            </td>
                            <td>
                              {{ props.item.numTrip }}
                            </td>
                            <td>
                              {{ new Date(props.item.date).toLocaleDateString() + " " + props.item.date.substr(11, 8) }}
                            </td>
                            <td>
                              {{ props.item.origen.dirOrigen }}
                            </td>
                            <td>
                              {{ props.item.destination.dirDestination }}
                            </td>
                            <td>"{{ props.item.transport.nameTransport }}"</td>
                            <td>
                              {{ props.item.driver.nameDriver }}
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </div>
                    <div v-if="cant_pages >= 1" class="text-center">
                      <v-pagination
                        class="mt-5 mb-5"
                        v-model="current_page"
                        :length="cant_pages"
                        total-visible="5"
                        circle
                        @next="loadPageCustom(service)"
                        @previous="loadPageCustom(service)"
                        @input="loadPageCustom(service)"
                      ></v-pagination>
                    </div>
                  </v-card>
                </v-stepper-content>

                <v-stepper-content step="2">
                  <v-card class="mb-12" color="#e1e1e1" height="initial">
                    <div class="col-md-12" v-if="remitos_selected.length > 0">
                      <v-data-table :headers="headers" :items="remitos_selected">
                        <template v-slot:item="props">
                          <tr>
                            <td>{{ props.item.remito }}</td>
                            <td>{{ props.item.client.nameClient }}</td>
                            <td>{{ props.item.subClient.nameSubClient }}</td>
                            <td>
                              {{ props.item.subClient.subClientAddress != null ? props.item.subClient.subClientAddress : " " }}
                            </td>
                            <td>
                              {{ props.item.subClient.locality.name != null ? props.item.subClient.locality.name : " " }}
                            </td>
                            <td>
                              {{ props.item.observation }}
                            </td>
                          </tr>
                        </template>
                        <template v-slot:no-results>
                          <v-alert :value="true" color="error" icon="warning">
                            {{ $t("sin_resultados") + search }}
                          </v-alert>
                        </template>
                      </v-data-table>
                    </div>
                    <div class="col-md-12" v-else>
                      <h3 style="text-align: center">
                        {{ $t("sin_remitos_seleccionados") }}
                      </h3>
                    </div>
                  </v-card>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary" class="btn-volver" @click="e1 = 1" v-if="e1 == 2">
              Volver
            </v-btn>
            <v-btn color="error" text @click="$emit('updateDialog', false), (ruta = ''), (e1 = 1), (selected_stepper = [])">{{ $t("Cerrar") }}</v-btn>

            <v-btn v-if="e1 == 1" color="success" text @click="e1 = 2" :disabled="!(selected_stepper.length >= 1)">{{ $t("Continuar") }} </v-btn>
            <v-btn v-if="remitos_selected.length > 0 && e1 == 2" color="success" text @click="AsignarRemitosARuta()">{{ $t("Asignar") }} </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { remitosService } from "@/libs/ws/remitosService";
import { rutasService } from "@/libs/ws/rutasService";

export default {
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    remitos_selected: Array,
  },
  data() {
    return {
      show: false,
      ruta: "",
      headersRutas: [
        { text: "N°" + this.$t("Ruta"), value: "number" },
        {
          text: this.$t("Fecha"),
          align: "left",
          sortable: true,
          value: "initialDate",
          width: "110px",
        },
        {
          text: this.$t("Origen"),
          align: "left",
          sortable: true,
          value: "origen.dirOrigen",
        },
        {
          text: this.$t("Destinatario"),
          align: "left",
          sortable: true,
          value: "destination.dirDestination",
        },
        {
          text: "Transporte",
          align: "left",
          sortable: true,
          value: "transport.nameTransport",
        },
        {
          text: this.$t("Chofer"),
          align: "left",
          sortable: true,
          value: "driver.nameDriver",
        },
      ],
      headers: [
        {
          text: "N° " + this.$t("Remito"),
          align: "left",
          sortable: true,
          value: "Remito",
          class: "lighten-2",
        },

        {
          text: this.$t("Cliente"),
          align: "left",
          sortable: true,
          value: "clients.nameClient",
          class: "lighten-2",
        },
        {
          text: "Sub " + this.$t("Cliente"),
          align: "left",
          sortable: true,
          value: "subclients.nameSubClient",
          class: "lighten-2",
        },
        {
          text: this.$t("Direccion"),
          align: "left",
          sortable: true,
          value: "subclients.subClientAddress",
          class: "lighten-2",
        },
        {
          text: "Localidad",
          align: "left",
          sortable: true,
          value: "subclients.locality.name",
          class: "lighten-2",
        },
        {
          text: "Observacion",
          align: "left",
          sortable: true,
          value: "observation",
          class: "lighten-2",
        },
      ],
      dateInicio_filter: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString().slice(0, 10),
      dateFin_filter: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString().slice(0, 10),
      servicio: rutasService,
      list: [],
      respuesta: false,
      rutasStepper: [],
      e1: 1,
      selected_stepper: [],
      cant_pages: 1,
      current_page: 1,
    };
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.filterRutas();
      }
    },
  },
  mounted() {},

  methods: {
    AsignarRemitosARuta() {
      const params = {
        ruta_id: parseInt(this.selected_stepper[0].id),
        remitos: [],
      };
      this.remitos_selected.forEach((element) => {
        params.remitos.push(element.id);
      });
      remitosService
        .AsignInBagToExistingtRoute(params)
        .then((response) => {
          this.$toast.success(this.$t("se_asigno_ok"), "OK");
          this.$emit("clearSelected");
          this.$emit("updateDialog", false);
        })
        .catch((response) => {
          this.$toast.error(response.title, "Error");
        });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    filterRutas(startIndex = 0) {
      var param = {
        dateInicio_filter: this.formatDate(this.dateInicio_filter),
        dateFin_filter: this.formatDate(this.dateFin_filter),
        chofer_filter: null,
        ruta_filter: "",
        remito_filter: "",
        onlyPendiente: true,
        vehiculo_filter: "",
      };
      //
      rutasService
        .filter(param, startIndex)
        .then((response) => {
          if (startIndex == 0) {
            this.current_page = 1;
          }
          this.rutasStepper = response.list;
          this.cant_pages = Math.ceil(response.quantity / this.$store.state.config.items_per_page);
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
        });
    },
    loadPageCustom(service) {
      this.filterRutas((this.current_page - 1) * 10);
    },
  },
};
</script>
