<template>
  <GmapMap
    :center="getCenter()"
    :zoom="11"
    :options="{
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: true,
      rotateControl: true,
      fullscreenControl: true,
      disableDefaultUi: true,
    }"
    map-type-id="terrain"
    style="width: 100%; height: 500px"
  >
    >
    <GmapMarker
      v-if="ruta.originPort != null && ruta.originPort.latitudAddress != null && ruta.originPort.longitudAddress != null"
      :position="getPosition(ruta.originPort.latitudAddress, ruta.originPort.longitudAddress)"
      :icon="getIcon('1')"
      :clickable="true"
      @click="window_1 = !window_1"
    >
      <GmapInfoWindow :opened="window_1" @closeclick="window_1 = false">
        <div style="padding:5px">
          <p class="fs-15 mb-0"><b>Puerto de recoleccion</b></p>
          <span class="fs-15">{{ ruta.originPort.dirOrigen }}</span>
        </div>
      </GmapInfoWindow>
    </GmapMarker>
    <GmapMarker
      v-if="ruta.warehouse != null && ruta.warehouse.latitudAddress != null && ruta.warehouse.longitudAddress != null"
      :position="getPosition(ruta.warehouse.latitudAddress, ruta.warehouse.longitudAddress)"
      :icon="getIcon('2')"
      :clickable="true"
      @click="window_2 = !window_2"
    >
      <GmapInfoWindow :opened="window_2" @closeclick="window_2 = false">
        <div style="padding:5px">
          <p class="fs-15 mb-0"><b>Deposito de desconsolidacion</b></p>
          <span class="fs-15">{{ ruta.warehouse.nameWarehouse }}</span>
        </div>
      </GmapInfoWindow>
    </GmapMarker>
    <GmapMarker
      v-if="ruta.destinationPort != null && ruta.destinationPort.latitudAddress != null && ruta.destinationPort.longitudAddress != null"
      :position="getPosition(ruta.destinationPort.latitudAddress, ruta.destinationPort.longitudAddress)"
      :icon="getIcon('3')"
      :clickable="true"
      @click="window_3 = !window_3"
    >
      <GmapInfoWindow :opened="window_3" @closeclick="window_3 = false">
        <div style="padding:5px">
          <p class="fs-15 mb-0"><b>Puerto de devolucion</b></p>
          <span class="fs-15">{{ ruta.destinationPort.dirOrigen }}</span>
        </div>
      </GmapInfoWindow>
    </GmapMarker>

    <GmapMarker
      v-if="ruta.driverPickup != null && ruta.driverPickup.latitud != null && ruta.driverPickup.longitud != null && ruta.stages[4].delivery == null"
      :position="getPosition(ruta.driverPickup.latitud, ruta.driverPickup.longitud)"
      :icon="require('@/assets/images/marker-truck.png')"
      :clickable="true"
      @click="windowDriverPick = !windowDriverPick"
    >
      <GmapInfoWindow :opened="windowDriverPick" @closeclick="windowDriverPick = false">
        <div style="padding:5px">
          <p class="fs-15 mb-0"><b>Chofer recoleccion</b></p>
          <span class="fs-15">{{ ruta.driverPickup.nameDriver }}</span>
        </div>
      </GmapInfoWindow>
    </GmapMarker>

    <GmapMarker
      v-if="ruta.driverDelivery != null && ruta.driverDelivery.latitud != null && ruta.driverDelivery.longitud != null && ruta.stages[4].delivery != null"
      :position="getPosition(ruta.driverDelivery.latitud, ruta.driverDelivery.longitud)"
      :icon="require('@/assets/images/marker-truck.png')"
      :clickable="true"
      @click="windowDriverDelivery = !windowDriverDelivery"
    >
      <GmapInfoWindow :opened="windowDriverDelivery" @closeclick="windowDriverDelivery = false">
        <div style="padding:5px">
          <p class="fs-15 mb-0"><b>Chofer entrega</b></p>
          <span class="fs-15">{{ ruta.driverDelivery.nameDriver }}</span>
        </div>
      </GmapInfoWindow>
    </GmapMarker>
  </GmapMap>
</template>

<script>
import { gmapApi } from "vue2-google-maps";

export default {
  name: "GeoMonitoringContainer",

  components: {},
  props: {
    ruta: Object,
  },
  data() {
    return {
      lngMin: null,
      latMin: null,
      lngMax: null,
      latMax: null,
      window_1: false,
      window_2: false,
      window_3: false,
      windowDriverPick: false,
      windowDriverDelivery: false,
    };
  },
  computed: {
    google: gmapApi,
  },
  watch: {},
  mounted() {},
  methods: {
    getCenter() {
      var lat = (this.latMax + this.latMin) / 2;
      var lng = (this.lngMax + this.lngMin) / 2;
      return { lat: lat, lng: lng };
    },
    getIcon(number) {
      return "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=" + number + "|7390fc|000000";
    },
    getPosition(lat, lng) {
      if (this.latMax == null || this.latMax < parseFloat(lat)) {
        this.latMax = parseFloat(lat);
      }

      if (this.latMin == null || this.latMin > parseFloat(lat)) {
        this.latMin = parseFloat(lat);
      }

      if (this.lngMax == null || this.lngMax < parseFloat(lng)) {
        this.lngMax = parseFloat(lng);
      }

      if (this.lngMin == null || this.lngMin > parseFloat(lng)) {
        this.lngMin = parseFloat(lng);
      }

      this.center = this.getCenter();

      return { lat: parseFloat(lat), lng: parseFloat(lng) };
    },
  },
};
</script>
