<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" v-on:input="$emit('updateDialog', false)" max-width="750px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->

            <div class="row">
              <div class="col-md-12">
                <v-text-field data-vv-as="nombre" :label="$t('Nombre')" name="nombre" v-model="item.name" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("nombre") }}</span>
                <v-data-table
                  :headers="headers_modules"
                  :items="item.modulos"
                  :loading="loading"
                  :hide-default-footer="true"
                  :disable-pagination="true"
                  :loading-text="$t('cargando_espere')"
                  :disabled="isViewModal()"
                >
                  <template v-slot:item="props" :disabled="isViewModal()">
                    <tr>
                      <!-- START ROWS -->
                      <!-- Nombre -->
                      <td :disabled="isViewModal()">
                        {{ props.item.name }}
                      </td>
                      <td>
                        <v-checkbox v-model="props.item.list" :disabled="isViewModal()"></v-checkbox>
                      </td>
                      <td>
                        <v-checkbox v-model="props.item.create" :disabled="isViewModal()"></v-checkbox>
                      </td>
                      <td>
                        <v-checkbox v-model="props.item.update" :disabled="isViewModal()"></v-checkbox>
                      </td>
                      <td>
                        <v-checkbox v-model="props.item.delete" :disabled="isViewModal()"></v-checkbox>
                      </td>
                      <td>
                        <v-checkbox v-model="props.item.sensible" :disabled="isViewModal()"></v-checkbox>
                      </td>
                      <!-- END ROWS -->
                    </tr>
                  </template>
                </v-data-table>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">{{ $t("Cerrar") }}</v-btn>
            <v-btn color="success" text type="submit">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { moduleService } from "@/libs/ws/moduleService";
export default {
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
  },
  data() {
    return {
      show: false,
      modules: [],
      loading: false,
      headers_modules: [
        { text: "Nombre", value: "name" },
        { text: "Listar", value: "list" },
        { text: this.$t("Crear"), value: "create" },
        { text: "Editar", value: "update" },
        { text: "Eliminar", value: "delete" },
        { text: "Sensible", value: "sensible" },
      ],
      flag: true,
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      if (this.dialog) {
        this.fillUpModule();
      }

      this.errors.clear();
    },
  },
  mounted() {
    this.loadModules();
  },
  methods: {
    isViewModal() {
      return this.formBtn == "";
    },
    loadModules() {
      let curretObject = this;
      moduleService
        .getAll()
        .then((response) => {
          this.modules = response;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    fillUpModule() {
      let curretObject = this;

      curretObject.modules.forEach(function(part, index, theArray) {
        const indexFound = curretObject.item.modulos.findIndex((element) => element.module.id == curretObject.modules[index].id);
        if (indexFound >= 0) {
          curretObject.item.modulos[indexFound].idModule = curretObject.modules[index].id;

          curretObject.item.modulos[indexFound].name = curretObject.modules[index].name;
        } else {
          curretObject.item.modulos.push({
            idModule: curretObject.modules[index].id,
            name: curretObject.modules[index].name,
            module: {
              id: curretObject.modules[index].id,
              name: curretObject.modules[index].name,
            },
            list: false,
            create: false,
            update: false,
            delete: false,
            sensible: false,
          });
        }
      });

      curretObject.item.modulos.sort(function(a, b) {
        return a.module.id - b.module.id;
      });
    },
    createItem() {
      let item_update = this.prepareModulesForRequest();
      if (this.item.id > 0) {
        this.service
          .update(item_update)
          .then((response) => {
            if (response.id > 0) {
              this.$toast.success(this.$t("se_actualizo_ok"), "OK");
              this.$emit("updateDialog", false);
              this.$emit("loadList");
            } else this.$toast.error(response.title, "Error");
          })
          .catch((response) => {
            this.$toast.error(response.title, "Error");
          });
      } else {
        this.service
          .create(item_update)
          .then((response) => {
            if (response.id > 0) {
              this.$toast.success(this.$t("se_creo_ok"), "OK");
              this.$emit("updateDialog", false);
              this.$emit("loadList");
            } else this.$toast.error(response.title, "Error");
          })
          .catch((response) => {
            this.$toast.error(response.title, "Error");
          });
      }
    },
    prepareModulesForRequest() {
      let item_update = { ...this.item };
      let activeModules = [];
      let curretObject = this;
      item_update.modulos.forEach(function(part, index, theArray) {
        if (item_update.modulos[index].list || item_update.modulos[index].create || item_update.modulos[index].update || item_update.modulos[index].delete || item_update.modulos[index].sensible)
          activeModules.push(item_update.modulos[index]);
      });
      item_update.modulos = activeModules;
      return item_update;
    },
  },
};
</script>
