<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" persistent v-on:input="$emit('updateDialog', false)" max-width="1000px">
      <template v-slot:activator="{ on }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" class="mb-5" dark v-on="on" v-bind="attrs">{{ button_name }}</v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title class="cursor" @click="$emit('updateDialog', true)">Crear ruta distribucion</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title class="cursor" @click="$router.push('/containers/routes/newroute')">Crear ruta contenedores</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="submitForm">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <v-text-field label="id" v-model="item.id" v-show="false" required></v-text-field>
            <div class="row">
              <div class="col-md-12">
                <v-stepper v-model="actualStep" elevation-0>
                  <v-stepper-header class="headerstep">
                    <v-stepper-step :complete="actualStep > 1" step="1"> {{ $t("Paso") }} 1 </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="actualStep > 2" step="2"> {{ $t("Paso") }} 2 </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="3"> {{ $t("Paso") }} 3 </v-stepper-step>
                  </v-stepper-header>

                  <v-stepper-items>
                    <v-stepper-content step="1" class="height-modal-stepper">
                      <div class="row">
                        <div class="col-md-3">
                          <v-menu ref="menu" :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field v-model="item.date" :label="$t('Fecha_inicio')" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" :disabled="isViewModal()"></v-text-field>
                            </template>
                            <v-date-picker v-model="item.date" min="1950-01-01"></v-date-picker>
                          </v-menu>
                        </div>
                        <div class="col-md-3">
                          <v-menu
                            ref="menu"
                            v-model="menu_time"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="item.time"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="item.time"
                                label="Hora de inicio"
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-validate="'required'"
                                v-bind="attrs"
                                name="time"
                                v-on="on"
                                :disabled="isViewModal()"
                              ></v-text-field>
                              <span class="red--text">{{ errors.first("time") }}</span>
                            </template>
                            <v-time-picker v-if="menu_time" v-model="item.time" full-width @click:minute="$refs.menu.save(item.time)"></v-time-picker>
                          </v-menu>
                        </div>
                        <div class="col-md-3">
                          <v-combobox
                            v-model="item.origen"
                            data-vv-as="Origen"
                            name="origen"
                            item-text="dirOrigen"
                            item-value="id"
                            :items="origin"
                            :label="$t('Origen')"
                            autocomplete="off"
                            v-validate="'required'"
                            :disabled="isViewModal()"
                          ></v-combobox>
                          <span class="red--text">{{ errors.first("origen") }}</span>
                        </div>
                        <div class="col-md-3">
                          <v-combobox
                            v-model="item.destination"
                            :data-vv-as="$t('Tipo_viaje')"
                            name="destino"
                            item-text="dirDestination"
                            item-value="id"
                            :items="destinations"
                            :label="$t('Tipo_viaje')"
                            autocomplete="off"
                            v-validate="'required'"
                            :disabled="isViewModal()"
                          ></v-combobox>
                          <span class="red--text">{{ errors.first("destino") }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <v-text-field v-model="item.externalCode" label="Id. Externo" outlined></v-text-field>
                        </div>
                        <div class="col-md-4">
                          <v-combobox
                            v-model="item.driver"
                            data-vv-as="Chofer"
                            name="chofer"
                            item-text="nameDriver"
                            item-value="id"
                            :items="drivers"
                            :label="$t('Chofer')"
                            autocomplete="off"
                            v-validate="'required'"
                            :disabled="isViewModal()"
                            @change="setVehiculo()"
                          ></v-combobox>
                          <span class="red--text">{{ errors.first("chofer") }}</span>
                        </div>
                        <div class="col-md-4">
                          <v-combobox
                            v-model="item.vehicle"
                            data-vv-as="Vehiculos"
                            name="vehiculo"
                            item-text="patent"
                            item-value="id"
                            :items="vehicles"
                            :label="$t('Vehiculo')"
                            autocomplete="off"
                            v-validate="'required'"
                            :disabled="isViewModal()"
                            @change="
                              loadTransportName();
                              loadTransportType();
                              loadMaxCharge();
                              updateBasicCost();
                              fillComboBoxAdicionales();
                            "
                          ></v-combobox>
                          <span class="red--text">{{ errors.first("vehiculo") }}</span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-4">
                          <v-text-field v-model="item.transport.nameTransport" :label="$t('Nombre') + ' transporte'" outlined disabled></v-text-field>
                        </div>
                        <div class="col-md-4">
                          <v-text-field v-model="item.transport.type" label="Tipo" outlined disabled></v-text-field>
                        </div>
                        <div class="col-md-4">
                          <v-text-field v-model="item.transport.maxCharge" label="Carga Máxima" outlined disabled></v-text-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <v-combobox
                            v-model="item.typeCharge"
                            data-vv-as="Servicio"
                            name="tipoCarga"
                            item-text="typeCharge"
                            item-value="id"
                            :items="cargoTypes"
                            label="Servicio"
                            autocomplete="off"
                            v-validate="'required'"
                            :disabled="isViewModal()"
                            @change="updateBasicCost(), fillComboBoxAdicionales()"
                          ></v-combobox>
                          <span class="red--text">{{ errors.first("tipoCarga") }}</span>
                        </div>

                        <div class="col-md-4">
                          <v-combobox
                            v-model="item.zone"
                            data-vv-as="Zona"
                            name="zona"
                            item-text="zone"
                            item-value="id"
                            :items="zones"
                            label="Zona"
                            autocomplete="off"
                            v-validate="'required'"
                            :disabled="isViewModal()"
                            @change="updateBasicCost(), fillComboBoxAdicionales()"
                          ></v-combobox>
                          <span class="red--text">{{ errors.first("zona") }}</span>
                        </div>
                        <div class="col-md-4">
                          <v-combobox
                            v-model="item.typeTrip"
                            :data-vv-as="$t('Tipo_contratacion')"
                            name="tipoViaje"
                            item-text="typeTrip"
                            item-value="id"
                            :items="typeTrips"
                            :label="$t('Tipo_contratacion')"
                            autocomplete="off"
                            v-validate="'required'"
                            :disabled="isViewModal()"
                            @change="updateBasicCost(), fillComboBoxAdicionales()"
                          ></v-combobox>
                          <span class="red--text">{{ errors.first("tipoViaje") }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <v-combobox
                            v-model="item.typeServiceCharge"
                            data-vv-as="Tipo de carga"
                            name="typeServiceCharge"
                            item-text="typeServiceCharge"
                            item-value="id"
                            :value="null"
                            :items="typeCharges"
                            label="Tipo de carga"
                            autocomplete="off"
                            :disabled="isViewModal()"
                          ></v-combobox>
                          <span class="red--text">{{ errors.first("companyCustody") }}</span>
                        </div>
                        <div class="col-md-4">
                          <v-combobox
                            v-model="item.companyCustody"
                            data-vv-as="Compañia de Seguro"
                            name="companyCustody"
                            item-text="nameCompany"
                            item-value="id"
                            :value="null"
                            :items="securityCompanies"
                            :label="$t('Compania_seguros')"
                            autocomplete="off"
                            :disabled="isViewModal()"
                          ></v-combobox>
                          <span class="red--text">{{ errors.first("companyCustody") }}</span>
                        </div>
                        <div class="col-md-4 ">
                          <v-text-field v-model="item.basicCost" label="Costo Básico" outlined disabled></v-text-field>
                        </div>
                      </div>
                    </v-stepper-content>

                    <v-stepper-content step="2" class="height-modal-stepper">
                      <div class="row mt-1">
                        <div class="col-md-9">
                          <v-combobox
                            v-model="adicional_selected"
                            data-vv-as="Adicional"
                            name="tipo"
                            :items="combobox_adicionales"
                            item-text="label"
                            item-value="tipo"
                            label="Adicionales"
                            autocomplete="off"
                            :disabled="isViewModal()"
                          ></v-combobox>
                        </div>
                        <div class="col-md-2">
                          <v-btn small color="primary" class="mt-5 white--text" @click="addAdicional(adicional_selected)" :disabled="isViewModal()">
                            <v-icon left dark>
                              add
                            </v-icon>
                            Agregar
                          </v-btn>
                        </div>
                      </div>
                      <div class="row mt-0">
                        <div class="col-md-12">
                          <div v-for="(adicional, index) of item.aditionals" :key="adicional.name">
                            <Adicional :adicional="adicional" @delete_adicional="delete_adicional" :index="index" @setQuantity="setQuantity" :isViewModal="isViewModal()"></Adicional>
                          </div>
                        </div>
                      </div>
                    </v-stepper-content>

                    <v-stepper-content step="3" class="height-modal-stepper">
                      <div class="row">
                        <div class="col-md-12">
                          <v-text-field
                            dense
                            v-model="item.peajes"
                            name="Peajes"
                            :label="$t('Peajes')"
                            :disabled="isViewModal()"
                            @change="calculateTotalCost()"
                            v-validate="{ decimal: [2, ','] }"
                          ></v-text-field>
                          <span class="red--text">{{ errors.first("Peajes") }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <v-text-field dense v-model="item.observation1" label="Operacion de referencia" :disabled="isViewModal()"></v-text-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <v-text-field dense v-model="item.observation2" :label="$t('Observaciones') + ' 2'" :disabled="isViewModal()"></v-text-field>
                        </div>
                      </div>
                      <div class="row  d-flex justify-content-end">
                        <div class="col-md-4">
                          <v-text-field dense label="Costo Total" :value="totalCost" outlined disabled></v-text-field>
                        </div>
                      </div>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>

                <div class="float-left">
                  <v-btn @click="backStep()" v-show="actualStep > 1" depressed color="error" class="mt-4 mr-4">
                    Atrás
                  </v-btn>
                </div>
                <div class="float-right">
                  <v-btn color="primary" @click="nextStep()" v-if="actualStep >= 1 && actualStep < 3" class="mt-4">
                    {{ $t("Siguiente") }}
                  </v-btn>
                </div>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">{{ $t("Cerrar") }}</v-btn>
            <v-btn v-if="formBtn != ''" color="success" text type="submit" :disabled="actualStep < 3">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { vehiculoService } from "@/libs/ws/vehiculoService";
import { choferesService } from "@/libs/ws/choferesService";
import { typeService } from "@/libs/ws/typeService";
import { typeCharge } from "@/libs/ws/typeCharge";
import { zoneService } from "@/libs/ws/zoneService";
import { typeTripService } from "@/libs/ws/typeTripService";
import { originService } from "@/libs/ws/originService";
import { tipoviajeService } from "@/libs/ws/tipoviajeService";
import { securityCompanyService } from "@/libs/ws/securityCompanyService";
import { purchaseRateService } from "@/libs/ws/purchaseRateService";
import Adicional from "./Adicionales.vue";
export default {
  components: {
    Adicional,
  },
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
  },
  data() {
    return {
      actualStep: 1,
      show: false,
      drivers: [],
      vehicles: [],
      typeCharges: [],
      cargoTypes: [],
      zones: [],
      typeTrips: [],
      origin: [],
      destinations: [],
      securityCompanies: [],
      peonCost: 0,
      stayCost: 0,
      turnsCost: 0,
      delayCost: 0,
      moduleCost: 0,
      moduleTotalCost: 0,
      totalCost: 0,
      purchaseRate: null,
      menu_time: false,

      cmbFiller: [0, 1, 2, 3, 4, 5],
      cmbFillerEstadias: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
      combobox_adicionales: [],
      adicionales: [],
      adicional_selected: null,
      total_cost_adicionales: 0,
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
      console.log(this.item);
      if (this.dialog) {
        this.actualStep = 1;
        this.item.time = this.item.date.substr(11, 8);

        var date = this.item.date.split("T");

        this.item.date = this.item.date != null ? date[0] : new Date();
        this.item.transport.maxCharge = "";
        this.item.transport.type = "";
        this.item.transport.nameTransport = "";
        this.updateBasicCost();
        this.$emit("updateItem", this.item);
      }
      if (this.dialog && this.item.id > 0) {
        this.loadTransportName();
        this.loadTransportType();
        this.loadMaxCharge();
      } else {
        this.item.aditionals = [];
      }
      this.adicional_selected = "";
    },
  },
  mounted() {
    this.loadVehiculos();
    this.loadChoferes();
    this.loadServices();
    this.loadZones();
    this.loadTripTypes();
    this.loadOrigenes();
    this.loadDestinations();
    this.loadSecurityCompanies();
    this.loadTypeCharges();
  },
  methods: {
    isViewModal() {
      return this.formBtn == "";
    },
    loadSecurityCompanies() {
      securityCompanyService
        .filter(" ", 0, 100, 1)
        .then((response) => {
          this.securityCompanies = response.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadDestinations() {
      tipoviajeService
        .getAll()
        .then((response) => {
          this.destinations = response.destinations;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadOrigenes() {
      originService
        .getAll()
        .then((response) => {
          this.origin = response.origins;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadTripTypes() {
      typeTripService
        .getAll()
        .then((response) => {
          this.typeTrips = response.types;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadZones() {
      zoneService
        .getAll()
        .then((response) => {
          this.zones = response.zones;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadVehiculos() {
      vehiculoService
        .getAll()
        .then((response) => {
          this.vehicles = response.vehicles;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadChoferes() {
      choferesService
        .getAll()
        .then((response) => {
          this.drivers = response.drivers;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadServices() {
      typeService
        .getAll()
        .then((response) => {
          this.cargoTypes = response.types;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadTypeCharges() {
      typeCharge
        .getAll()
        .then((response) => {
          this.typeCharges = response.types;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    createItem() {
      console.log(this.item, "item");
      this.item.date = this.formatDate(this.item.date);

      if (this.item.id > 0) {
        this.service
          .updateNew(this.item)
          .then((response) => {
            if (response.id > 0) {
              this.$toast.success(this.$t("se_actualizo_ok"), "OK");
              this.$emit("updateDialog", false);
              this.$emit("loadList");
            } else this.$toast.error(response.title, "Error");
          })
          .catch((response) => {
            this.$toast.error(response.title, "Error");
          });
      } else {
        this.service
          .createNew(this.item)
          .then((response) => {
            if (response.id > 0) {
              this.$toast.success(this.$t("se_creo_ok"), "OK");
              this.$emit("updateDialog", false);
              this.$emit("loadList");
              this.$emit("id_rutaCreada", response.id);
            } else this.$toast.error(response.title, "Error");
          })
          .catch((response) => {
            this.$toast.error(response.title, "Error");
          });
      }
    },
    validarStep1() {
      var flag = true;
      if (this.item.time == "") {
        flag = false;
        this.errors.add({
          field: "time",
          msg: "Por favor ingrese la hora de inicio",
        });
      }
      if (this.item.destination == "") {
        flag = false;
        this.errors.add({
          field: "destino",
          msg: "Por favor ingrese el tipo de viaje",
        });
      }
      if (this.item.driver == "") {
        flag = false;
        this.errors.add({
          field: "chofer",
          msg: "Por favor ingrese el chofer",
        });
      }

      if (this.item.vehicle == "") {
        flag = false;
        this.errors.add({
          field: "vehiculo",
          msg: "Por favor ingrese el vehiculo",
        });
      }

      if (this.item.origen == "") {
        flag = false;
        this.errors.add({
          field: "origen",
          msg: "Por favor ingrese el Origen",
        });
      }

      if (this.item.typeCharge == "") {
        flag = false;
        this.errors.add({
          field: "tipoCarga",
          msg: "Por favor ingrese el Servicio",
        });
      }

      if (this.item.zone == "") {
        flag = false;
        this.errors.add({
          field: "zona",
          msg: "Por favor ingrese la zona",
        });
      }
      if (this.item.typeTrip == "") {
        flag = false;
        this.errors.add({
          field: "tipoViaje",
          msg: "Por favor ingrese el tipo de contratacion",
        });
      }
      this.fillComboBoxAdicionales();
      return flag;
    },
    validarStep2() {
      var flag = true;
      this.calculateTotalCost();
      return flag;
    },

    validarStep3() {
      var flag = true;
      if (this.item.quantityModules == null) {
        flag = false;
        this.errors.add({
          field: "quantityModules",
          msg: "Por favor ingrese la cantidad de modulos",
        });
      }
      return flag;
    },
    nextStep() {
      if (this.actualStep != 3) {
        if ((this.actualStep == 1 && this.validarStep1()) || (this.actualStep == 2 && this.validarStep2())) {
          this.actualStep++;
          this.errors.clear();
        }
      }
    },

    backStep() {
      if (this.actualStep != 1) {
        this.actualStep--;
      }
    },
    loadTransportName() {
      var index = this.vehicles.findIndex((object) => {
        return object.id === this.item.vehicle.id;
      });
      var object = this.vehicles[index];
      this.item.transport.nameTransport = object.companyTransport.nameTransport;
    },
    loadTransportType() {
      var index = this.vehicles.findIndex((object) => {
        return object.id === this.item.vehicle.id;
      });
      var object = this.vehicles[index];
      this.item.transport.type = object.type.type;
    },
    loadMaxCharge() {
      var index = this.vehicles.findIndex((object) => {
        return object.id === this.item.vehicle.id;
      });
      var object = this.vehicles[index];
      this.item.transport.maxCharge = object.maxCharge;
    },
    submitForm() {
      if (this.validarStep3()) {
        this.createItem();
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    updateBasicCost() {
      /*
      if (this.item.typeCharge != "" && this.item.zone != "" && this.item.vehicle != "") {
        purchaseRateService
          .getBasicCost(this.item.vehicle.type.id, this.item.typeCharge.id, this.item.zone.id)
          .then((response) => {
            this.item.basicCost = response.basicCost;
            this.purchaseRate = response;
          })
          .catch((e) => {
            console.log("service.load error");
            console.log(e);
          });
      }
      */
    },
    fillComboBoxAdicionales() {
      if (this.item.typeCharge != "" && this.item.zone != "" && this.item.vehicle != "") {
        purchaseRateService
          .FindPurchaseRateNewV2(this.item.vehicle.type.id, this.item.typeCharge.id, this.item.zone.id, this.item.vehicle.companyTransport.id)
          .then((response) => {
            response.adicionales.forEach((element) => {
              element.label = this.$t(element.tipo);
              element.quantity = 1;
            });
            this.combobox_adicionales = response.adicionales;
            this.item.basicCost = response.basicCost;
            this.updateAdicionales();
          })
          .catch((e) => {
            console.log("service.load error");
            console.log(e);
          });
      }
    },
    updateAdicionales() {
      /*
      if (this.item.aditionals.length > 0) {
        var aux = {};
        var i = 0;
        this.item.aditionals.forEach((element) => {
          var aux_i = -1;
          this.combobox_adicionales.forEach((element1) => {
            if (element.tipo == element1.tipo) {
              console.log("entre");
              aux_i = i;
              aux = {
                id: element.id,
                adicional: element.adicional,
                tipo: element1.tipo,
                quantity: element.quantity, //porque tengo que guardarme la cantidad que ya tenía antes
                cost: element1.cost,
              };
            }
          });
          if (aux_i >= 0) {
            this.item.aditionals[i] = Object.assign({}, aux);
          }
          i++;
        });
      }
      console.log(this.item.aditionals, "adicionales aca");
      */
    },

    calculateCustodyCost() {
      if (this.item.companyCustody != null) {
        this.moduleTotalCost = parseFloat(this.item.companyCustody.cost) * parseFloat(this.item.quantityModules);
        this.calculateTotalCost();
      } else {
        this.moduleTotalCost = 0;
        this.item.quantityModules = 0;
      }
    },
    calculateTotalCost() {
      var adicionales_cost = this.calculateAditionalsCost();
      //var peaje = this.item.peajes == "" ? 0 : parseInt(this.item.peajes);
      this.totalCost = adicionales_cost + this.moduleTotalCost + this.item.basicCost;

      this.item.totalCost = this.totalCost;
    },
    calculateAditionalsCost() {
      var costo_total = 0;
      this.item.aditionals.forEach((element) => {
        var costo_unitario = 0;
        costo_unitario = element.quantity * element.cost;
        costo_total += costo_unitario;
      });
      return costo_total;
    },
    setVehiculo() {
      this.item.vehicle = this.item.driver.vehicle;
      this.loadTransportName();
      this.loadTransportType();
      this.loadMaxCharge();
      this.updateBasicCost();
      this.fillComboBoxAdicionales();
    },
    addAdicional(adicional) {
      const index = this.item.aditionals.map((e) => e.tipo).indexOf(adicional.tipo);
      if (index > -1) {
        this.$toast.error("No se pueden repetir servicios", "Error");
      } else {
        console.log("add adicional", adicional);
        console.log("aditionales antes", this.item.aditionals);
        this.item.aditionals.push(adicional);
        console.log("aditionales", this.item.aditionals);
        this.calculateTotalCost();
      }
      this.adicional_selected = "";

      console.log(this.item, "item");
    },
    delete_adicional(adicional) {
      const index = this.item.aditionals.map((e) => e.tipo).indexOf(adicional.tipo);
      if (index > -1) {
        this.item.aditionals.splice(index, 1);
      }
      this.adicional_selected = "";
    },
    setQuantity(index, quantity) {
      console.log(quantity);
      this.item.aditionals[index].quantity = quantity;
    },
  },
};
</script>
