import { genericService } from "./genericService";

const section = "User";

function filter(name = " ", from = "0", limit = "10", active = "1") {
  return genericService.filter(section, "users", name, from, limit, active);
}

function getAll() {
  return genericService.get("User/ListAllUser");
}

function remove(id) {
  return genericService.remove(section, id);
}

function create(item) {
  const param = {
    Name: item.name,
    Username: item.username,
    Password: item.password,
    Email: item.email,
    MobileNumber: item.mobileNumber,
    IsActive: item.isActive,
    IdRol: item.rol.id,
    isExternal: item.isExternal,
    idCompanyExternal: item.isExternal ? item.companyExternal.id : 0,
    
  };
  console.log(param);
  return genericService.create(section, param);
}

function update(item) {
  const param = {
    id: item.id,
    Name: item.name,
    Username: item.username,
    Password: item.password == "" ? null : item.password,
    Email: item.email,
    MobileNumber: item.mobileNumber,
    IsActive: item.isActive,
    IdRol: item.rol.id,
    isExternal: item.isExternal,
    idCompanyExternal: item.isExternal ? item.companyExternal.id : 0,
    OrganizationBaseId: item.organizationBaseId
  };

  return genericService.update(section, param);
}

export const usersService = {
  filter,
  getAll,
  remove,
  create,
  update,
};
