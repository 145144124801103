<template>
  <v-app class="scrollbar-hidden" v-if="!viewRequiresAuth()">
    <router-view></router-view>
  </v-app>

  <v-app class="scrollbar-hidden" v-else>
    <v-navigation-drawer v-model="$store.state.sidebar.drawer" :width="240" app :expand-on-hover="!$store.state.sidebar.open" class="rounded-0">
      <Sidebar></Sidebar>
    </v-navigation-drawer>

    <v-app-bar class="elevation-0" dense app>
      <Header></Header>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Sidebar from "@/components/Sidebar.vue";

export default {
  name: "App",
  components: { Sidebar, Header },
  data: () => ({}),
  created() {
    document.title = "Akeron | Logistica";
  },
  mounted() {
    /*  this.$i18n.locale = "en"; */
  },
  methods: {
    viewRequiresAuth() {
      return this.$route.matched.some((record) => record.meta.requiresAuth);
    },
  },
};
</script>
