import { genericService } from "./genericService";
import { akeronService } from "./akeronService";

const section = "Monitoreo";

function getAll() {
  return genericService.get("Monitoreo/MonitoreoHeaderTrip");
}

function filter(params) {
  const param = {
    Fecha: params.Fecha,
    NumTrip: params.numTrip,
    numRemito: params.numRemito,
    IdClient: params.clientId,
  };

  return akeronService.make_post_request("Monitoreo/MonitoreoHeaderTrip", param).then((response) => {
    return response;
  });
}

export const monitoreoService = {
  getAll,
  filter,
};
