<template>
  <v-dialog v-model="dialog" max-width="650">
    <v-card>
      <v-card-title class="headline">Cambio de estado</v-card-title>

      <v-card-text class="">
        <p class="mt-5 mb-0 fs-16 text-center">Seleccione el estado que desea otorgarle a la ruta</p>

        <div class="d-flex justify-content-center">
          <div class="col-md-6 mt-4">
            <v-combobox v-if="items != null" v-model="colorModel" :items="items" label="Estado">
              <template v-slot:selection="{ item }">
                <v-progress-linear :value="100" :color="'rgba(' + item.id + ')'" style="height: 20px !important;">
                  <span class="text-white">{{ item.name }}</span>
                </v-progress-linear>
              </template>
              <template v-slot:item="{ item }">
                <v-progress-linear :value="100" :color="'rgba(' + item.id + ')'" style="height: 20px !important;">
                  <span class="text-white">{{ item.name }}</span>
                </v-progress-linear>
              </template>
            </v-combobox>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="red darken-1" text @click="$emit('close')">
          Cerrar
        </v-btn>
        <v-btn color="green" text @click="$emit('change', colorModel)">
          Editar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { rutasService } from "@/libs/ws/rutasService";
export default {
  props: {
    dialog: Boolean,
    close: Function,
    change: Function,
    color: String,
  },
  data() {
    return {
      colorModel: null,
      items: null,
    };
  },
  watch: {
    color() {
      this.colorModel = this.color;
    },
  },
  mounted() {
    this.items = rutasService.getStates();
  },
  methods: {},
};
</script>
